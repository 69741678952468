import {Injectable, Injector} from "@angular/core";
import {DocumentDetails} from "../model/document-details.model";
import JsonMapUtils from "../utilities/utils/json-map.util";
import {DocumentField} from "../model/document-field";
import {DocumentFormBuilderComponent} from "../claim-pages/document-form-builder/document-form-builder.component";
import {DocumentFormFactoryService} from "./document-form-factory.service";
import { TransactionInfoService } from "./transaction-info.service";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Observable, catchError, map, throwError} from 'rxjs';
import { FileLikeObject } from "ng2-file-upload";
import { Notifications } from "../utilities/components/notification-messages/notifications.model";
import { TranslateService } from "@ngx-translate/core";
import { ErrorsService } from "../utilities/errors/errors.service";
import { Claimant } from "../model/claimant.model";
import { DeviceDetectorService } from "ngx-device-detector";
import { FileErrorType } from "../model/file-error-type.model";
import {DocumentSearchResult} from '../model/search-claim/document-search-result.model';

@Injectable()
export class DocumentHelperService {

    documentFormBuilders: DocumentFormBuilderComponent[]  = [];

    constructor(private documentFormFactoryService: DocumentFormFactoryService,
            private translateService: TranslateService,
            private injector: Injector,
            private deviceService: DeviceDetectorService,
                private http: HttpClient) {
    }

    getDocumentFormBuilder(claimType: string): DocumentFormBuilderComponent{
        let docFormBuilder: any;
        this.documentFormBuilders.forEach(function(d){
            if(d.documentFormClaimType != undefined && d.documentFormClaimType == claimType){
                docFormBuilder = d;
                return;
            }
        });
        return docFormBuilder;
    }

    addDocumentFormBuilder(claimType) {
        let newDocBuilder = this.documentFormFactoryService.getDocumentBuilder(claimType);
        newDocBuilder.setRequiredDocuments();
        this.setDocumentFormBuilder(newDocBuilder);
    }

    setDocumentFormBuilder(docFormBuilder: DocumentFormBuilderComponent) {
        let existingDocumentBuilderFound = false;
        this.documentFormBuilders.forEach(function(d, index){
            if(docFormBuilder.documentFormClaimType == d.documentFormClaimType){
                existingDocumentBuilderFound = true;
                return;
            }
        });
        if(!existingDocumentBuilderFound){
            this.documentFormBuilders.push(docFormBuilder);
        }
    }

    getDocumentFormBuilders(): DocumentFormBuilderComponent[]{
        return this.documentFormBuilders;
    }

    mapDocumentDetails(data: string): DocumentDetails {
        return JsonMapUtils.deserialize(DocumentDetails, data);
    }

    validateFile(file: FileLikeObject, notifications: Notifications, docType: DocumentField): boolean {
        notifications.showPreamble = false;
        notifications.removeFileErrorMessage(docType.getId());
        let hasError = false;
        
        if (file.size > this.getMaxDocFileSize()) {
            hasError = true;
            notifications.addFileErrorMessage('error_messages.file.maxSize', file.name, 
                FileErrorType[FileErrorType.FILE_SIZE_LIMIT], docType.getId());
        }
    
        const regExp = /(?:\.([^.]+))?$/;
        const fileExt = regExp.exec(file.name)[1].toLowerCase();
       /* if (!(fileExt && this.getValidFileTypes().indexOf(fileExt) !== -1)) {
            hasError = true;
            notifications.addFileErrorMessage('error_messages.file.invalidFileType', file.name, 
                FileErrorType[FileErrorType.INVALID_FILE_TYPE], docType.getId());
        }*/

        if(file.name.length > this.getMaxFileNameLength()){
            hasError = true;
            notifications.addFileErrorMessage('error_messages.file.maxFileNameLength', file.name, 
                FileErrorType[FileErrorType.FILE_NAME_LIMIT], docType.getId());
        }

        //if hasError, scroll up to top of page.
        if (hasError) {
            window.scroll(0, 0);
        }
        
        return hasError;
      }

    getFileUploadingErrorMessage(filename: string, notifications: Notifications, docTypeId: string) {
         notifications.addFileErrorMessage('error_messages.file.uploadFailed', filename, 
                FileErrorType[FileErrorType.UPLOAD_FAILED], docTypeId);
    }


    getMaxDocFileSize(): number {
        return 20971520;
    }

    isImage(fileName: string): boolean {
        if(this.deviceService.getDeviceInfo().browser!='IE'){
            const regExp = /(?:\.([^.]+))?$/;
            const fileExt = regExp.exec(fileName)[1].toLowerCase();
            if('jpg,jpeg,png'.indexOf(fileExt) !== -1){
                return true;
            }
        }  
        return false;
    }

    getValidFileTypes(): string {
        var re = /(?:\.([^.]+))?$/;
        return 'doc,docx,xls,xlsx,pdf,txt,jpg,jpeg,png,gif,avi,mgg,mp4,mov,wmv';
    }

    getMaxFileNameLength(): number {
        return 80;
    }

    getListOfDocuments(uploadedDocuments: DocumentDetails[], requiredDocuments: DocumentField[], claimType: string): DocumentField[] {
        let summaryOfDocuments: DocumentField[] = [];
        requiredDocuments.forEach(function (requiredDoc) {
            uploadedDocuments.forEach(function (uploadedDoc) {
                if (uploadedDoc.docClaimType == claimType && uploadedDoc.docSubTypeId.startsWith(requiredDoc.id)) {
                    requiredDoc.setUploadedFileName(uploadedDoc.docFileName);
                    return;
                }
            });
            summaryOfDocuments.push(requiredDoc);
        });

        let prevDocSubTypeId = 'none';
        uploadedDocuments.forEach(function (uploadedDoc) {
            if (uploadedDoc.docClaimType == claimType && uploadedDoc.docSubTypeId.startsWith('OTHERS')) {
                let otherDoc: DocumentField = new DocumentField('OTHERS', 'pageSummaryClaim.supportDocuments.title', 'Others', true);
                if (uploadedDoc.docSubTypeId.startsWith(prevDocSubTypeId)) {
                    otherDoc.setDescription('');
                }
                otherDoc.setUploadedFileName(uploadedDoc.docFileName);
                summaryOfDocuments.push(otherDoc);
            }
            if (!uploadedDoc.docSubTypeId.startsWith(prevDocSubTypeId) && uploadedDoc.docSubTypeId.startsWith('OTHERS')) {
                prevDocSubTypeId = 'OTHERS';
            }
        });
        return summaryOfDocuments;
    }

    getListOfUploadedDocumentsClaimant(uploadedDocuments: DocumentDetails[], requiredDocuments: DocumentField[], claimType: string, isMinor: boolean): DocumentField[] {
        if(!isMinor) {
            requiredDocuments = requiredDocuments.filter(x => !x.id.startsWith('PROOFOFREL'));
        }
        return this.getListOfUploadedDocuments(uploadedDocuments, requiredDocuments, claimType);
    }

    getListOfUploadedDocuments(uploadedDocuments: DocumentDetails[], requiredDocuments: DocumentField[], claimType: string): DocumentField[] {
        let summaryOfDocuments: DocumentField[] = [];
        let uploadedDocs: DocumentDetails[] = [];

        //Get all the uploaded documents for the claim type
        uploadedDocuments.forEach(function (uploadedDoc) {
            if (uploadedDoc.docClaimType == claimType) {
                uploadedDocs.push(uploadedDoc);
                uploadedDocs.sort((a,b) => a.docSubTypeId.localeCompare(b.docSubTypeId));
            }
        });

        let prevDocTypeId = 'none';
        //Sorts the uploaded documents
        requiredDocuments.forEach(function(requiredDoc){
            let isUploaded = false;
            let hasAnyDocsUploaded = false;
            let reqDoc: DocumentField;
            prevDocTypeId = 'none';

            uploadedDocs.forEach(function(uploadedDoc, index) {
               reqDoc = new DocumentField(requiredDoc.getId(), requiredDoc.getDescription(), requiredDoc.getSmartqField(), requiredDoc.getIsRequired());
                if(uploadedDoc.docSubTypeId.startsWith(requiredDoc.id)){
                    isUploaded = true;
                    if (uploadedDoc.docSubTypeId.startsWith(prevDocTypeId)) {
                        reqDoc.setDescription('');
                    }
                    reqDoc.setUploadedFileName(uploadedDoc.docFileName);
                    summaryOfDocuments.push(reqDoc);
                    hasAnyDocsUploaded = true;
                }
                if (!uploadedDoc.docSubTypeId.startsWith(prevDocTypeId) && !uploadedDoc.docSubTypeId.startsWith('OTHERS')) {
                    let subTypeId = uploadedDoc.docSubTypeId;
                    if (uploadedDoc.docSubTypeId.slice(0, -1) == requiredDoc.id) {
                        subTypeId = uploadedDoc.docSubTypeId.slice(0, -1);
                    }
                    prevDocTypeId = subTypeId;

                    if(subTypeId.indexOf("_")!== -1){
                        subTypeId = subTypeId.split("_")[0];
                        if (subTypeId == 'COLLISION' || subTypeId == 'THEFT') {
                            prevDocTypeId = prevDocTypeId;
                        } else {
                            if (subTypeId.slice(0, -1) == requiredDoc.id) {
                                subTypeId = subTypeId.slice(0, -1);
                            }
                            prevDocTypeId = subTypeId;
                        }
                    }
                }
                if(uploadedDocs.length == index + 1 && isUploaded == false){
                    summaryOfDocuments.push(reqDoc);
                    hasAnyDocsUploaded = true;
                }

            });

            if (!hasAnyDocsUploaded) {
                reqDoc = new DocumentField(requiredDoc.getId(), requiredDoc.getDescription(), requiredDoc.getSmartqField(), requiredDoc.getIsRequired());
                summaryOfDocuments.push(reqDoc);
            }

        });

        return summaryOfDocuments;
    }

    getListOfOtherUploadedDocuments(uploadedDocuments: DocumentDetails[], requiredDocuments: DocumentField[], claimType: string): DocumentField[] {
        let summaryOfDocuments: DocumentField[] = [];
        let prevDocSubTypeId = 'none';

        uploadedDocuments.forEach(function (uploadedDoc) {
            if (uploadedDoc.docClaimType == claimType && uploadedDoc.docSubTypeId.startsWith('OTHERS')) {
                let otherDoc: DocumentField = new DocumentField('OTHERS', 'pageSummaryClaim.supportDocuments.title', 'Others', false);
                if (uploadedDoc.docSubTypeId.startsWith(prevDocSubTypeId)) {
                    otherDoc.setDescription('');
                }
                otherDoc.setUploadedFileName(uploadedDoc.docFileName);
                summaryOfDocuments.push(otherDoc);
            }
            if (!uploadedDoc.docSubTypeId.startsWith(prevDocSubTypeId) && uploadedDoc.docSubTypeId.startsWith('OTHERS')) {
                prevDocSubTypeId = 'OTHERS';
            }
        });
        return summaryOfDocuments;
    }

    getOtherDocumentField(index: number) {
        return new DocumentField('OTHERS' + index, 'pageSummaryClaim.supportDocuments.title', 'Others', true);
    }

    getDocumentFieldClaimant(docField: DocumentField, claimant: Claimant) {
        return new DocumentField(docField.getId() + '_' + claimant.getClaimantIdentifier(), docField.getDescription(), docField.getSmartqField(), docField.getIsRequired());
    }

    getOtherDocumentFieldClaimant(index: number, claimant: Claimant) {
        return new DocumentField('OTHERS' + index  + '_' + claimant.getClaimantIdentifier(), 'pageSummaryClaim.supportDocuments.title', 'Others', true);
    }
    getIndexedDocumentField(docField: DocumentField, index: number) {
        let dF = new DocumentField(docField.getId() + index, docField.getDescription(), docField.getSmartqField(), docField.getIsRequired());
        dF.setIndex(index);
        dF.setRiskId(docField.getRiskId());
        return dF;
    }
    getIndexedDocumentFieldClaimant(docField: DocumentField, index: number, claimant: Claimant) {
        let dF = new DocumentField(docField.getId() + index + '_' + claimant.getClaimantIdentifier(), docField.getDescription(), docField.getSmartqField(), docField.getIsRequired());
        dF.setIndex(index);
        return dF;
    }

    removeDocument(documentId: string, transactionInfoService: TransactionInfoService, http: HttpClient,
                   hasAutoSaveId: boolean): boolean {

        let integrationToken = transactionInfoService.getTransactionInfo().getIntegrationToken();
        let documentForm: DocumentDetails = new DocumentDetails();

        if (documentId !== '') {
            documentForm.docID = documentId;
            documentForm.hasAutoSaveId = hasAutoSaveId;
        }

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + integrationToken
            })
        };

        let url: string = '/ms/claims-service/rest/documents/remove';
        // ...using get request
        console.log('Calling  removal url');
        let removalSuccessful: boolean = true;
        http.post(url, JSON.stringify(documentForm), httpOptions).subscribe(res => {
            console.log('http return result');
            console.log(res);
            if (res == true || res == "true") {
                removalSuccessful = true;
                console.log('IT IS TRUE');
            }
            return res;
        }),
            //...errors if any
            catchError(this.handleError);

        console.log('document removal success', removalSuccessful);
        return removalSuccessful;
    }

    private handleError(error: Response | any) {
        // In a real world app, you might use a remote logging infrastructure
        const errorsService = this.injector.get(ErrorsService); 
        let errMsg: string;
        if (error instanceof Response) {
            errMsg = `${error.status} - ${error.statusText || ''}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        errMsg = 'documentservice handleError' + errMsg;
        errorsService.log(errMsg);
        return throwError(() => new Error(errMsg));
    }

    getErrorMessage(messageKey: string): string{
        let errorMessage: string = '';
        this.translateService.stream(messageKey).subscribe((res:string) => {
            errorMessage = res;
        });
        return errorMessage;
    }

    getDefaultLabel(isMobile: boolean): string {
        let defaultText = '';

        if(!isMobile){
            defaultText = '<i class="fas fa-upload"></i>' + this.getErrorMessage('claimSection.generalLabels.uploadDocuments.dragAndDropHere');
          } else {
              defaultText = '<i class="fas fa-camera"></i>' + this.getErrorMessage('claimSection.generalLabels.uploadDocuments.takeAPhoto');
          }

          return defaultText;
    }

    getClaimDetailsDocuments(claimsToken: string): Observable<DocumentSearchResult> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + claimsToken
            })
        };

        const url: string = '/ms/claims-service/rest/get/documentList';

        return this.http.get<DocumentSearchResult>(url, httpOptions)
            .pipe(map((res: DocumentSearchResult) => {

                let documentSearchResult = new DocumentSearchResult();
                Object.assign(documentSearchResult, res);

                if (documentSearchResult.getDocuments()) {
                    const map = new Map(Object.entries(documentSearchResult.getDocuments()));
                    documentSearchResult.setDocuments(map);
                }

                return documentSearchResult;
            }))
            .pipe(catchError<any,Observable<DocumentSearchResult>>(this.handleError));
    }

    downloadDocument(claimToken: string, dDocName: string, documentToken: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + claimToken
            }),
            'responseType': 'blob' as 'blob'
        };

        const formData = new FormData();
        formData.append('dDocName', dDocName);
        formData.append('documentToken', documentToken);

        return this.http.post('/ms/claims-service/rest/downloadDocument', formData, httpOptions);
    }

}
