import { Component, OnInit, Input, Output, EventEmitter, Injectable, Renderer2, ChangeDetectorRef, Injector } from '@angular/core';
import { bodyParts } from './body-parts'
import { injury } from './injury-list'
import { ClaimsBaseComponent } from 'src/app/claim-pages/claim-base.component';
import { PageService } from 'src/app/services/page.service';
import {InjuredBodyPart} from '../../../../model/employee-compensation/injured-body-part.model';
import {BodyPart} from '../../../../model/employee-compensation/body-part.model';
import {ClaimEmployeeCompensation} from '../../../../model/claim-employee-compensation.model';
import {TransactionInfoService} from '../../../../services/transaction-info.service';
import {ClaimTypes} from '../../../../model/claim-type.model';

@Component({
  selector: 'app-body-parts',
  templateUrl: './body-parts.component.html',
  styleUrls: ['./body-parts.component.css']
})

export class BodyPartsComponent extends ClaimsBaseComponent implements OnInit {

  claimEmployeeCompensation: ClaimEmployeeCompensation;
  injuredBodyParts: InjuredBodyPart[];
  isOpen = false;
  isOpenF = false;
  getArea = '';
  temp: any[] = []; // store temporary selected        // modal table display
  table: any[] = []; // store all main table
  newTable: any[] = []; // store all newTable were arrays are grouped by area // main table display
  selectHead: any[] = []; // specific area head
  selectNeckTrunk: any[] = []; // specific area neck / trunk
  selectLeftArm: any[] = []; // specific area arm left
  selectRightArm: any[] = []; // specific area arm right
  selectLeftHand: any[] = []; // specific area hand left
  selectRightHand: any[] = []; // specific area hand right
  selectLeftLeg: any[] = []; // specific area leg left
  selectRightLeg: any[] = []; // specific area leg right
  selectLeftFoot: any[] = []; // specific area foot left
  selectRightFoot: any[] = []; // specific area foot right
  fetchedData: any[] = bodyParts;
  temp2: any[] = [];
  gender = '';
  isLoading = true;
  isSubmitted = false;
  injuryList: any[] = injury;
  tooltipText = '';
  mouseX = 0;
  mouseY = 0;
  data: any = [];
  showErrorMessage = false;
  selectedAreaTable: any[] = [];

  constructor(private renderer: Renderer2, private cdr: ChangeDetectorRef, private tooltipService: PageService, private injector : Injector) {
    super(injector);
    this.claimEmployeeCompensation = this.claim.getClaimEmployeeCompensation();
    let injuredBodyParts = this.claimEmployeeCompensation.getAccidentDetails().getInjuredBodyParts();
    this.newTable = this.transformIntoArea(injuredBodyParts);
    this.temp = this.transformIntoItems(injuredBodyParts);
    //this.temp2 = this.transformIntoItems(injuredBodyParts);
    this.selectHead = this.temp.filter((group) => group.area.includes('head'));
    this.selectNeckTrunk = this.temp.filter((group) => group.area.includes('neck-trunk'));
    this.selectLeftArm = this.temp.filter((group) => group.area.includes('left-arm'));
    this.selectRightArm = this.temp.filter((group) => group.area.includes('right-arm'));
    this.selectLeftHand = this.temp.filter((group) => group.area.includes('left-hand'));
    this.selectRightHand = this.temp.filter((group) => group.area.includes('right-hand'));
    this.selectLeftLeg = this.temp.filter((group) => group.area.includes('left-leg'));
    this.selectRightLeg = this.temp.filter((group) => group.area.includes('right-leg'));
    this.selectLeftFoot = this.temp.filter((group) => group.area.includes('left-foot'));
    this.selectRightFoot = this.temp.filter((group) => group.area.includes('right-foot'));
  }

  ngOnInit() {
      if (this.injector.get(TransactionInfoService).getTransactionInfo().getCurrentClaimType() === ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2B] &&
            !this.claimEmployeeCompensation.getAccidentDetails().getInjuredGender()) {
        this.claimEmployeeCompensation.getAccidentDetails().setInjuredGender('Male');
      }

      this.gender = this.claimEmployeeCompensation.getAccidentDetails().getInjuredGender() ?
          this.claimEmployeeCompensation.getAccidentDetails().getInjuredGender() :
          this.claimEmployeeCompensation.getEmployeeDetails().getGender() ?
              this.claimEmployeeCompensation.getEmployeeDetails().getGender() : 'Male';
  }

  onClickGender(gender: string){
    this.gender = gender;
    this.temp = [];
    this.table = [];
    this.newTable = [];
    this.selectHead = [];
    this.selectNeckTrunk = []; 
    this.selectLeftArm = []; 
    this.selectRightArm = []; 
    this.selectLeftHand = []; 
    this.selectRightHand = []; 
    this.selectLeftLeg = []; 
    this.selectRightLeg = []; 
    this.selectLeftFoot = []; 
    this.selectRightFoot= [];
    this.claimEmployeeCompensation.getAccidentDetails()?.setInjuredGender(gender);

    if (this.injector.get(TransactionInfoService).getTransactionInfo().getCurrentClaimType() === ClaimTypes[ClaimTypes.CLAIM_TYPE_EC_FORM2]) {
        this.claimEmployeeCompensation.getEmployeeDetails()?.setGender(gender);
        this.claimEmployeeCompensation.getEmployeeDetails()?.setGenderDesc(
            this.translate.instant('ecClaim.employeeDetails.gender.' + gender?.toLowerCase()));
    }
  }

  getAreaRowCount(area) {
    return this.table.filter((item) => item.area === area).length;
  }

  checkAreaExists(area:string) {
    return this.table.some((item) => item.area === area);
  }

  onClickArea(e: any) {
    this.getArea = e.target.parentElement.id || e.target.name;
    if (this.getArea) {
      if(this.gender === 'Male'){
        this.isOpen = !this.isOpen;
      } else if(this.gender === 'Female'){
        this.isOpenF = !this.isOpenF;
      }
      document.body.classList.add('_modal-open');

      if (this.temp.length > 0) {
        this.selectedAreaTable = this.temp.filter(item => this.getArea.includes(item.area));
      }
    }
  }

  isSelected(id: string) {
    return this.temp.some((obj) => obj.id === id);
  }

  changeColor(selected) {
    return selected ? 'red' : 'green';
  }

  //selected specific body part
  onClickSpecificArea(id: string, area: string) {
    const matchItems = this.fetchedData?.filter((item) => item.id === id);

    if (matchItems.length > 0) {
        matchItems.map((matched) => {
        const index = this.temp?.findIndex((obj) => obj.id === id);

        if (index === -1) {
          this.temp?.push({ ...matched, id: matched.id });
        } else {
          this.temp?.splice(index, 1);
        }
      });
    } else {
      // ID is already in the array
      console.log(`ID '${id}' is already in the 'temp' array.`);
    }

    this.updateModalTable(area);
  }

  // remove selected body parts from modal
  onClickRemoveSpecificArea(id: string, area: string) {
    this.temp = this.temp.filter((item: any) => item.id !== id);
    //this.temp2 = this.temp2.filter((item: any) => item.id !== id);
    this.selectHead = this.selectHead.filter((item: any) => item.id !== id);
    this.selectNeckTrunk = this.selectNeckTrunk.filter((item: any) => item.id !== id);
    this.selectLeftArm = this.selectLeftArm.filter((item: any) => item.id !== id);
    this.selectRightArm = this.selectRightArm.filter((item: any) => item.id !== id);
    this.selectLeftHand = this.selectLeftHand.filter((item: any) => item.id !== id);
    this.selectRightHand = this.selectRightHand.filter((item: any) => item.id !== id);
    this.selectLeftLeg = this.selectLeftLeg.filter((item: any) => item.id !== id);
    this.selectRightLeg = this.selectRightLeg.filter((item: any) => item.id !== id);
    this.selectLeftFoot = this.selectLeftFoot.filter((item: any) => item.id !== id);
    this.selectRightFoot= this.selectRightFoot.filter((item: any) => item.id !== id);
  }

   // remove selected body parts from main table
   onClickRemoveSpecificAreaMain(id: string, area: string) {
      this.temp = this.temp.filter((item: any) => item.id !== id);
      this.table = [...this.temp];

      // remove items from table
       this.table = this.table.filter((obj: any) => obj.id !== id);

       // remove items from newTable
       let areaObject = this.newTable.find((item: any) => item.area === area);

       if (areaObject) {
          areaObject[area] = areaObject[area].filter((item: any) => item.id !== id);
          if (areaObject[area].length === 0) {
            this.newTable = this.newTable.filter((item: any) => item.area !== area);
          }
        }

        //this.temp2 = this.temp2.filter((item: any) => item.id !== id);
        this.selectHead = this.selectHead.filter((item: any) => item.id !== id);
        this.selectNeckTrunk = this.selectNeckTrunk.filter((item: any) => item.id !== id);
        this.selectLeftArm = this.selectLeftArm.filter((item: any) => item.id !== id);
        this.selectRightArm = this.selectRightArm.filter((item: any) => item.id !== id);
        this.selectLeftHand = this.selectLeftHand.filter((item: any) => item.id !== id);
        this.selectRightHand = this.selectRightHand.filter((item: any) => item.id !== id);
        this.selectLeftLeg = this.selectLeftLeg.filter((item: any) => item.id !== id);
        this.selectRightLeg = this.selectRightLeg.filter((item: any) => item.id !== id);
        this.selectLeftFoot = this.selectLeftFoot.filter((item: any) => item.id !== id);
        this.selectRightFoot= this.selectRightFoot.filter((item: any) => item.id !== id);

        let transformedBP = this.transformBodyParts(this.newTable);
        this.claimEmployeeCompensation.getAccidentDetails().setInjuredBodyParts(transformedBP);
    }

  // add or update selected body parts
  onSubmitSelected() {
    if (this.temp.length !== 0) {
      this.table = [...this.temp];
      ///this.temp2 = [...this.temp];
      this.isOpen = false;
      this.isOpenF = false;
      document.body.classList.remove('_modal-open');
      const groupedItems: any = {};

      // group by area
      this.table.forEach((item: any) => {
        if (!groupedItems[item.area]) {
          groupedItems[item.area] = [];
        }
        groupedItems[item.area].push(item);
      });

      // group and add keys where the keys is the area
      this.newTable = Object.keys(groupedItems).map((area) => {
        const obj: any = { area };
        obj[area] = groupedItems[area];
        return obj;
      });

      let transformedBP = this.transformBodyParts(this.newTable);
      this.claimEmployeeCompensation.getAccidentDetails().setInjuredBodyParts(transformedBP);
      this.showErrorMessage = false;
    } 
  }

  // cancel or close from modal
  onClickCloseSpecificArea() {
    this.isOpen = false;
    this.isOpenF = false;    
    document.body.classList.remove('_modal-open');

    //merge the temp and the current select area - body parts
    if (this.getArea && this.temp && this.selectedAreaTable) {
      this.temp = [ ...this.temp.filter(item => !this.getArea.includes(item.area)), ...this.selectedAreaTable];
      this.selectedAreaTable = [];
    }

    this.updateModalTable(this.getArea);
  }

  // show what is stored in the modal table
  updateModalTable (area: string) {
      if (area.includes('head')) {
          this.selectHead = this.temp.filter((group) => group.area.includes('head'));
      } else if (area.includes('neck-trunk')) {
          this.selectNeckTrunk = this.temp.filter((group) => group.area.includes('neck-trunk'));
      } else if (area.includes('left-arm')) {
          this.selectLeftArm = this.temp.filter((group) => group.area.includes('left-arm'));
      } else if (area.includes('right-arm')) {
          this.selectRightArm = this.temp.filter((group) => group.area.includes('right-arm'));
      } else if (area.includes('left-hand')) {
          this.selectLeftHand = this.temp.filter((group) => group.area.includes('left-hand'));
      } else if (area.includes('right-hand')) {
          this.selectRightHand = this.temp.filter((group) => group.area.includes('right-hand'));
      } else if (area.includes('left-leg')) {
          this.selectLeftLeg = this.temp.filter((group) => group.area.includes('left-leg'));
      } else if (area.includes('right-leg')) {
          this.selectRightLeg = this.temp.filter((group) => group.area.includes('right-leg'));
      } else if (area.includes('left-foot')) {
          this.selectLeftFoot = this.temp.filter((group) => group.area.includes('left-foot'));
      } else if (area.includes('right-foot')) {
          this.selectRightFoot = this.temp.filter((group) => group.area.includes('right-foot'));
      }
  }

  customSearchFn(term: any) {
    return this.injuryList.filter((item: any) => item.name.toLowerCase().includes(term.toLowerCase()));
  }

  addTagFn(label: any) {
    const newCar = { id: label, label };
    return newCar;
  }

  onOpen(e: any) {
    if (e.filterInput.nativeElement.value === '') {
      e.close();
    }
  }

  @Input() posX: any;
  @Input() posY: any;
  @Input() labelTxt: any;

  @Output() emitMousePosMove = new EventEmitter<any>();
  MousePosMove(e:any) {
    this.emitMousePosMove.emit(e);
  }

  @Output() emitShowLabelTxt = new EventEmitter<any>();
  showLabelTxt(txt:any) {
    this.emitShowLabelTxt.emit(txt);
  }

  @Output() emitHideLabelTxt = new EventEmitter<any>();
  hideLabelTxt() {
    this.emitHideLabelTxt.emit();
  }

  onClickNotClose(e: any) {
    e.stopPropagation();
  }

  showTooltip(txt: string) {
    this.tooltipService.showTooltip(this.translate.instant(txt));
  }

  hideToolTip() {
    this.tooltipService.hideTooltip()
  }
  
  transformIntoArea(injuredParts: any) {
    let transformArea = [];

    if (injuredParts) {
        injuredParts.forEach(function (injuredPart) {
            let transformedArea = {
                area: injuredPart.area,
                [injuredPart.area.toLowerCase()]: injuredPart.bodyParts.map((bodyPart: any) => {

                    let transformedBodyPart = {
                        area: injuredPart.area.toLowerCase(),
                        areaLabel: injuredPart.areaDesc,
                        areaValue: injuredPart.areaValue,
                        id: bodyPart.bodyPartValue.replace(/\s+/g, "-").toLowerCase(),
                        label: bodyPart.bodyPartDesc,
                        value: bodyPart.bodyPartValue,
                        injury: []
                    };

                    for (let x = 0; x < bodyPart.natureOfInjury.length; x++) {
                        transformedBodyPart.injury.push({
                            label: bodyPart.natureOfInjuryDesc[x],
                            value: bodyPart.natureOfInjury[x]
                        });
                    }

                    return transformedBodyPart;
                })
            };

            transformArea.push(transformedArea);
        });
    }

    return transformArea;
   }

  transformIntoItems(injuredParts: any) {
    let bodyParts = [];

    if (injuredParts) {
        injuredParts.forEach((area: any) => {
            area.bodyParts.forEach((bodyPart: any) => {

                let transformedBodyPart = {
                    area: area.area,
                    areaLabel: area.areaDesc,
                    areaValue: area.areaValue,
                    id: bodyPart.bodyPartValue.replace(/\s+/g, "-").toLowerCase(),
                    label: bodyPart.bodyPartDesc,
                    value: bodyPart.bodyPartValue,
                    formBodyPart: bodyPart.formBodyPart,
                    injury: []
                };

                for (let x = 0; x < bodyPart.natureOfInjury.length; x++) {
                    transformedBodyPart.injury.push({
                        label: bodyPart.natureOfInjuryDesc[x],
                        value: bodyPart.natureOfInjury[x]
                    });
                }

                bodyParts.push(transformedBodyPart);
            });
        });
    }

    return bodyParts;
  }

  transformBodyParts(bodyParts: any[]): any[] {
      let injuredParts: InjuredBodyPart[] = [];

      bodyParts.forEach((bodyPart: any) => {
          let bodyPartArea = bodyPart[bodyPart.area];
          let injuredPart = new InjuredBodyPart();
          let bodyPartlist: BodyPart[] = [];

          bodyPartArea.forEach((bodyPart: any) => {
            let bodyPartItem = new BodyPart();

            bodyPartItem.setBodyPartId(bodyPart.id);
            bodyPartItem.setBodyPartValue(bodyPart.value);
            bodyPartItem.setBodyPartDesc(this.translate.instant(bodyPart.label));
            bodyPartItem.setFormBodyPart(bodyPart.formBodyPart);

            let natureOfInjury: string[] = [];
            let natureOfInjuryDesc: string[] = [];
            bodyPart.injury.forEach((injury: any) => {
              natureOfInjury.push(injury.value);
              natureOfInjuryDesc.push(this.translate.instant(injury.label));
            });

            bodyPartItem.setNatureOfInjury(natureOfInjury);
            bodyPartItem.setNatureOfInjuryDesc(natureOfInjuryDesc);
            bodyPartlist.push(bodyPartItem);
            injuredPart.setAreaValue(bodyPart.areaValue);
            injuredPart.setAreaDesc(this.translate.instant(bodyPart.areaLabel));
          });

          injuredPart.setBodyParts(bodyPartlist);
          injuredPart.setArea(bodyPart.area);

          injuredParts.push(injuredPart);
      });

      return injuredParts;
  }

  validateBodyPartsForm() {
    let result = this.validateBodyPartsSelection();
    this.showErrorMessage = !result;
    return result;
  }

  validateBodyPartsSelection() {
    if (this.claimEmployeeCompensation.getAccidentDetails()?.getInjuredBodyParts() &&
        this.claimEmployeeCompensation.getAccidentDetails()?.getInjuredBodyParts().length > 0) {
        if (this.claimEmployeeCompensation?.getAccidentDetails()?.getInjuredBodyParts().find(injuredBodyPart =>
            injuredBodyPart?.getBodyParts()?.length == 0 || injuredBodyPart.getBodyParts().find( bodyPart =>
            !this.isNotNullOrUndefinedStr(bodyPart.getBodyPartValue()) || !this.isNotNullOrUndefined(bodyPart.getNatureOfInjury())
            || bodyPart.getNatureOfInjury()?.length == 0))) {
            return false;
        }
        return true;
    }

    return false;
  }

}


