<button *ngIf="transactionInfo.getIsTransactionOrigin()" class="btn btn-primary new-btn float-left" (click)="open(open_dialog_abandon,'open_dialog_abandon')">
     {{'claimSection.generalLabels.generalButtonLabels.abandon' | translate}} 
</button>


<ng-template #open_dialog_abandon let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title cursor-pointer">{{'abandon.title' | translate}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row content">
        <p>{{'abandon.warning' | translate}}</p>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="abandon()">{{'abandon.yes' | translate}}</button>
      <button type="button" class="btn btn-light" (click)="c('Close click')">{{'abandon.no' | translate}}</button>
    </div>
  </ng-template>