// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// This file will be cand allow to be replace ung filereplacements array in ng-build prod replace envionrment .ts with enrionve,ent.pro d tsprod ts

export const environment = {
  production: false,
    gaID: {
        travel: 'GTM-57FMRXB',
        domesticHelper: 'GTM-KD6VR4K',
        motor: 'GTM-WTB2SDS',
        home: 'GTM-W8SQQQF',
        accident: 'GTM-ML8D5WH',
        generic: 'GTM-KZBQ843',
        claimStatus: 'GTM-WS337FC'
    },
    faq: {
        accident_hkg_en: 'https://auth-au.qbe.com/hk/en/eclaims-faqs',
        accident_hkg_zh: 'https://auth-au.qbe.com/hk/zh-hk/eclaims-faqs',
        domestichelper_hkg_en: 'https://auth-au.qbe.com/hk/en/helper-claims-faqs',
        domestichelper_hkg_zh: 'https://auth-au.qbe.com/hk/zh-hk/helper-claims-faqs',
        ec_hkg_en: 'https://auth-au.qbe.com/hk/en/eclaims-faqs',
        ec_hkg_zh: 'https://auth-au.qbe.com/hk/zh-hk/eclaims-faqs',
        generic_hkg_en: 'https://auth-au.qbe.com/hk/en/eclaims-faqs',
        generic_hkg_zh: 'https://auth-au.qbe.com/hk/zh-hk/eclaims-faqs',
        generic_sgp_en: 'https://auth-au.qbe.com/sg/eclaims-faqs',
        generic_mys_en: 'https://auth-au.qbe.com/my/claims/general-eclaims-faqs',
        home_hkg_en: 'https://auth-au.qbe.com/hk/en/home-claims-faqs',
        home_hkg_zh: 'https://auth-au.qbe.com/hk/zh-hk/home-claims-faqs',
        motor_hkg_en: 'https://auth-au.qbe.com/hk/en/motor-claims-faqs',
        motor_hkg_zh: 'https://auth-au.qbe.com/hk/zh-hk/motor-claims-faqs',
        travel_hkg_en: 'https://auth-au.qbe.com/hk/en/claims-faqs',
        travel_hkg_zh: 'https://auth-au.qbe.com/hk/zh-hk/claims-faqs'
    },
    videoId: {
        hkg_en: 'lcPReB6M5i4',
        hkg_zh: '6t8R-Ug-Au4',
        hkg_en_motor: 'M--yuG5J4j4',
        hkg_zh_motor: 'km_S5DEQs5A',
        sgp_en: '32wUZ_vu1pM'
    },
    eClaims: {
        hkg: 'https://auth-au.qbe.com/hk/eclaims',
        sgp: 'https://auth-au.qbe.com/sg/eclaims',
        mys: 'https://auth-au.qbe.com/my/claims'
    }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
